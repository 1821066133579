<!-- 战略意图 -->
<template>
	<div class="app-container">
		<formItem
			class="queryForm"
			style="margin: 10px 0"
			:formItems="formItems"
			@cancel="$router.go(-1)"
			:defaultData="formModel"
			:showFooter="false"
		>
		</formItem>
		<div class="views_wrap">
			<empty v-if="!tableRealData.length" />
			<div
				v-for="(item, inx) in tableRealData"
				:key="item.lineId"
				:class="`view_item ${isFullScreen && fullScreenInx === inx && 'fullscreen'}`"
			>
				<div @click="() => handleTitle(inx)">
					<el-row class="titleRow">
						<el-col :span="2"><i :class="activeNames.includes(inx) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i></el-col>
						<el-col :span="8" v-for="ite in titleList" :key="ite.key">
							<div class="titleText">
								<span>{{ ite.label }}: </span>
								<span>{{ ite.formatter ? ite.formatter(item[ite.key]) : item[ite.key] || '' }}</span>
							</div>
						</el-col>
						<el-col :span="14" style="text-align: right; padding-right: 10px" v-show="!isDownLoading">
							<!-- <el-button size="mini" type="primary" icon="el-icon-edit" @click.stop="() => handleEditAnalysis(item)"> 编辑 </el-button> -->
							<el-button
								size="mini"
								type="danger"
								icon="el-icon-delete"
								@click.stop="() => handleDelAnalysis(item)"
								v-if="permissionControlBtns(pageName, 'Delete')"
							>
								删除
							</el-button>
						</el-col>
					</el-row>
				</div>

				<el-collapse-transition>
					<div class="wo_table_wrap" v-show="activeNames.includes(inx)">
						<div class="wo_table_item" :style="isFullScreen && `height: 100vh`">
							<el-row style="margin: 20px; display: flex; align-items: center; justify-content: center">
								<el-col :span="6">
									<el-card class="box-card">
										<div slot="header" style="text-align: center">
											<span>愿景</span>
										</div>
										<div>
											<el-row>
												<el-col :span="22" class="contentWord">{{ item.vision || '' }}</el-col>
												<el-col :span="2" v-if="permissionControlBtns(pageName, 'EditVision')">
													<div style="cursor: pointer; color: #409eff; margin-left: 10px">
														<i
															class="el-icon-edit"
															style="margin-right: 4px"
															title="编辑"
															@click="() => handleEditIcon(1, item)"
														></i>
													</div>
												</el-col>
											</el-row>
										</div>
									</el-card>
								</el-col>
								<el-col :span="2" class="arrowWrap"><div class="arrow"></div></el-col>
								<el-col :span="6">
									<el-card class="box-card">
										<div slot="header" style="text-align: center">
											<span>战略目标</span>
										</div>
										<div>
											<el-row>
												<el-col :span="22" class="contentWord"
													>{{ item.overallStartTime ? `${item.overallStartTime}财年-${item.overallEndTime}财年：` : ''
													}}{{ item.strategicGoals || '' }}</el-col
												>
												<el-col :span="2" v-if="permissionControlBtns(pageName, 'EditTarget')">
													<div style="cursor: pointer; color: #409eff; margin-left: 10px">
														<i
															class="el-icon-edit"
															style="margin-right: 4px"
															title="编辑"
															@click="() => handleEditIcon(2, item)"
														></i>
													</div>
												</el-col>
											</el-row>
										</div>
									</el-card>
								</el-col>
								<el-col :span="2" class="arrowWrap"><div class="arrow"></div></el-col>
								<el-col :span="8">
									<el-card class="box-card">
										<div slot="header" style="text-align: center">
											<span>年度里程碑</span>
										</div>
										<div>
											<el-row
												v-for="it in item.milestonesList"
												:key="it.id"
												style="padding-bottom: 4px; border-bottom: 1px solid #efefef; margin-bottom: 10px"
											>
												<el-col :span="20" class="contentWord">
													{{ it.startTime ? `${it.startTime}财年-${it.endTime}财年：` : '' }}{{ it.milestones || '' }}
												</el-col>
												<el-col :span="4" style="text-align: right">
													<div style="cursor: pointer; margin-left: 10px">
														<i
															class="el-icon-edit"
															style="margin-right: 4px; color: #409eff"
															title="编辑"
															@click="() => handleEditIcon(3, { ...item, ...it, id: item.id, cId: it.id })"
															v-if="permissionControlBtns(pageName, 'EditMilepost')"
														></i>
														<i
															class="el-icon-delete"
															style="margin-right: 4px; color: #f56c6c"
															title="删除"
															@click="() => handleDelAnalysis(item, it)"
															v-if="permissionControlBtns(pageName, 'DelMilepost')"
														></i>
													</div>
												</el-col>
											</el-row>
											<div
												class="addFunctions"
												@click="() => handleEditIcon(4, item)"
												v-if="permissionControlBtns(pageName, 'AddMilepost')"
											>
												<i class="el-icon-plus" style="margin-right: 4px"></i>
											</div>
										</div>
									</el-card>
								</el-col>
							</el-row>
						</div>
					</div>
				</el-collapse-transition>
				<!-- <div class="wo_table_wrap" v-show="activeNames.includes(inx)">
					<div class="wo_table_item" :style="isFullScreen && `height: 100vh`">
						<el-row style="margin: 20px; display: flex; align-items: center; justify-content: center">
							<el-col :span="6">
								<el-card class="box-card">
									<div slot="header" style="text-align: center">
										<span>愿景</span>
									</div>
									<div>
										<el-row>
											<el-col :span="22" class="contentWord">{{ item.vision || '' }}</el-col>
											<el-col :span="2">
												<div style="cursor: pointer; color: #409eff; margin-left: 10px">
													<i
														class="el-icon-edit"
														style="margin-right: 4px"
														title="编辑"
														@click="() => handleEditIcon(1, item)"
													></i>
												</div>
											</el-col>
										</el-row>
									</div>
								</el-card>
							</el-col>
							<el-col :span="2"><div class="arrow"></div></el-col>
							<el-col :span="6">
								<el-card class="box-card">
									<div slot="header" style="text-align: center">
										<span>战略目标</span>
									</div>
									<div>
										<el-row>
											<el-col :span="22" class="contentWord"
												>{{ item.overallStartTime ? `${item.overallStartTime}财年-${item.overallEndTime}财年：` : ''
												}}{{ item.strategicGoals || '' }}</el-col
											>
											<el-col :span="2">
												<div style="cursor: pointer; color: #409eff; margin-left: 10px">
													<i
														class="el-icon-edit"
														style="margin-right: 4px"
														title="编辑"
														@click="() => handleEditIcon(2, item)"
													></i>
												</div>
											</el-col>
										</el-row>
									</div>
								</el-card>
							</el-col>
							<el-col :span="2"><div class="arrow"></div></el-col>
							<el-col :span="8">
								<el-card class="box-card">
									<div slot="header" style="text-align: center">
										<span>年度里程碑</span>
									</div>
									<div>
										<el-row
											v-for="it in item.milestonesList"
											:key="it.id"
											style="padding-bottom: 4px; border-bottom: 1px solid #efefef; margin-bottom: 10px"
										>
											<el-col :span="20" class="contentWord">
												{{ it.startTime ? `${it.startTime}财年-${it.endTime}财年：` : '' }}{{ it.milestones || '' }}
											</el-col>
											<el-col :span="4" style="text-align: right">
												<div style="cursor: pointer; margin-left: 10px">
													<i
														class="el-icon-edit"
														style="margin-right: 4px; color: #409eff"
														title="编辑"
														@click="() => handleEditIcon(3, { ...item, ...it, id: item.id, cId: it.id })"
													></i>
													<i
														class="el-icon-delete"
														style="margin-right: 4px; color: #f56c6c"
														title="删除"
														@click="() => handleDelAnalysis(item, it)"
													></i>
												</div>
											</el-col>
										</el-row>
										<div class="addFunctions" @click="() => handleEditIcon(4, item)">
											<i class="el-icon-plus" style="margin-right: 4px"></i>
										</div>
									</div>
								</el-card>
							</el-col>
						</el-row>
					</div>
				</div> -->
			</div>
		</div>
		<!-- 添加产品线 -->
		<el-dialog :title="dialogTitle" top="30vh" center :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false">
			<formItem
				class="formItem"
				:formItems="dialogItems"
				@submit="this.addAnalysisSubmit"
				@cancel="dialogVisible = false"
				:defaultData="dialogModel"
				style="margin: 0 50px"
			>
			</formItem>
		</el-dialog>
		<!-- 新增编辑行 以及单元格 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="600px"
			center
			:visible.sync="addNodeVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogNodeItems"
				@submit="this.addAnalysisSubmit"
				@cancel="addNodeVisible = false"
				:defaultData="editData"
			>
			</formItem>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<!-- <Upload :visible.sync="dialogImportVisible" :config="uploadConfig" /> -->
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
// import baseTable from '@/views/components/components-base-table';
import empty from '@/views/components/components-empty/index.vue';
// import Upload from '@/components/Upload/index.vue';
// import { debounce } from 'loadsh';
import { Loading } from 'element-ui';
// import { printDiv } from '@/utils/util';
export default {
	name: 'marketInsightindustry',
	components: { formItem, empty },

	data() {
		return {
			radarLoading: false,
			visible: true,
			dialogVisible: false,
			dialogModel: {},
			dialogItems: [
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					class: 'pruductClass',
					defaultFirstOption: true,
					options: () => {
						const { setData } = this.$store.state;

						return setData.options.productId || [];
					},
					optEdit: true,
					footerRender: true,
					optProps: { add: '/v1/common/productConfig/save', del: '/v1/common/productConfig/delete', key: 'name' },
					rules: [{ required: true, message: '请选择', trigger: 'change' }],
					span: 24
				}
			],
			formItems: [
				{
					label: '财年',
					key: 'annual',
					type: 'yearrange',
					span: 6,
					labelWidth: '60px'
				},
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					async: () =>
						this.$axios
							.get('/v1/common/productConfig/getList', { request: {} })
							.then((res) => res.map(({ id, name }) => ({ dictId: id, dictName: name }))),
					span: 6
				},
				{
					span: 3,
					labelWidth: '0px',
					style: 'min-width: 100px',
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.queryData}>
							查询
						</el-button>
					)
				},
				{
					span: 9,
					labelWidth: '0px',
					style: 'text-align: right;min-width: 310px',
					render: () => (
						<div style="min-width: 310px">
							{this.permissionControlBtns(this.pageName, 'Add') && (
								<el-button
									size="small"
									type="primary"
									style="margin-top: 5px"
									icon="el-icon-plus"
									onclick={() => {
										this.dialogModel = {};
										this.dialogTitle = '添加产品线';
										this.dialogType = 'add';
										this.dialogVisible = true;
									}}
								>
									添加产品线
								</el-button>
							)}
						</div>
					)
				}
			],
			formModel: { annual: [] },
			titleList: [{ label: '产品线', key: 'productName' }],
			addNodeVisible: false,
			editData: {},
			dialogTitle: '新增',
			dialogType: '',
			activeNames: [0],
			isDownLoading: false,
			dialogImportVisible: false,
			// 导入配置
			// uploadConfig: {
			// 	dictId: 'importTargetInfo',
			// 	groupId: 'importTemplate',
			// 	importUrl: '/v1/performance/target/importTargetInfo',
			// 	successMsg: '年度目标差距分析导入成功',
			// 	callBack: () => {
			// 		this.queryData();
			// 		this.dialogImportVisible = false;
			// 	}
			// },
			isFullScreen: false,
			fullScreenInx: '',
			tableRealData: [],
			showOperation: []
		};
	},
	watch: {},
	computed: {
		pageName() {
			console.log(this.$route.name, 'this.$route.name');
			return this.$route.name;
		},
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		dialogNodeItems() {
			let arr = [
				{
					label: '愿景',
					key: 'vision',
					type: 'textarea',
					span: 24,
					// rows: 4,
					// autosize: false,
					rules: { required: true, message: '请输入', trigger: 'blur' }
				}
			];

			switch (this.dialogType) {
				case 'zl':
					arr = [
						{
							label: '财年',
							key: 'yearrange',
							type: 'yearrange',
							span: 24,
							rules: { required: true, message: '请输入', trigger: 'blur' }
						},
						{
							label: '战略目标',
							key: 'strategicGoals',
							type: 'textarea',
							span: 24,
							rules: { required: true, message: '请输入', trigger: 'blur' }
						}
					];
					break;
				case 'nd':
				case 'and':
					arr = [
						{
							label: '财年',
							key: 'milestonesYear',
							type: 'yearrange',
							span: 24,
							rules: { required: true, message: '请输入', trigger: 'blur' }
						},
						{
							label: '里程碑',
							key: 'milestones',
							type: 'textarea',
							span: 24,
							rules: { required: true, message: '请输入', trigger: 'blur' }
						}
					];
					break;

				default:
					break;
			}

			return arr;
		}
	},
	async mounted() {
		// 查询数据
		this.queryData();
	},

	methods: {
		// 查询数据视图
		queryData() {
			const loading = Loading.service({ target: '.views_wrap' });
			const params = { ...this.formModel };

			if (params.annual?.length) {
				params.startTime = params.annual[0];
				params.endTime = params.annual[1];
			}
			this.$delete(params, 'annual');

			this.$axios
				.post('/v1/doRightThing/strategicIntent/getList', { request: params })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}

					this.$delete(res, '_responseStatusCode');

					this.tableRealData = res.map((item) => ({ ...item, milestonesList: item.milestonesList || [] }));
				})
				.catch(() => {
					loading.close();
				});
		},
		// 添加/编辑[分析]提交
		addAnalysisSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					let params = {
						...this.dialogModel,
						type: ['nd', 'and'].includes(this.dialogType) ? '2' : '1'
					};

					if (['yj', 'zl', 'nd', 'and'].includes(this.dialogType)) {
						params = { ...this.editData, ...params };
						// 新增 编辑 里程碑
						if (['and', 'nd'].includes(this.dialogType)) {
							if (params.milestonesYear) {
								const { milestonesYear, milestones, strategicIntentId, cId } = params;

								params.milestonesList = [
									{
										startTime: milestonesYear[0],
										endTime: milestonesYear[1],
										milestones,
										strategicIntentId,
										id: cId
									}
								];
								this.$delete(params, 'milestonesYear');
							}
						}
					}
					// 财年
					if (params.yearrange?.length) {
						params.overallStartTime = params.yearrange[0];
						params.overallEndTime = params.yearrange[1];
						this.$delete(params, 'yearrange');
					}
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/doRightThing/strategicIntent/save', {
							request: params
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.addNodeVisible = false;
							this.$message.success('保存成功');
							this.dialogModel = {};
							this.editData = {};
							this.queryData();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		handleEditIcon(inx, data) {
			this.dialogTitle = '编辑';

			switch (inx) {
				case 1:
					this.dialogType = 'yj';
					break;
				case 2:
					this.dialogType = 'zl';
					break;
				case 3:
					this.dialogType = 'nd';
					break;
				case 4:
					this.dialogType = 'and';
					this.dialogTitle = '新增年度里程碑';
					this.editData = {};
					break;

				default:
					break;
			}
			const params = { ...data };

			if (params.overallStartTime && params.overallEndTime && !['and', 'nd'].includes(this.dialogType)) {
				params.yearrange = [params.overallStartTime, params.overallEndTime];
			}
			if (params.startTime && params.endTime && ['nd'].includes(this.dialogType)) {
				params.milestonesYear = [params.startTime, params.endTime];
			}
			this.editData = params;
			this.addNodeVisible = true;
		},

		// 编辑行业分析
		handleEditAnalysis({ id, productId, productName, industry, tableId }) {
			this.dialogType = 'editAnalysis';
			const params = {
				id,
				tableId,
				productId,
				productName,
				industry
			};

			this.dialogModel = params;
			this.dialogTitle = '编辑';
			this.dialogVisible = true;
		},
		// 删除 意图 年度里程碑
		handleDelAnalysis(data, e = {}) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				const params = { id: data.id, type: '1' };

				if (e?.id) {
					params.type = '2';
					params.milestonesIds = [e.id];
					this.$delete(params, 'id');
				}

				this.$axios
					.post('/v1/doRightThing/strategicIntent/delete', {
						request: params
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.isFullScreen = false;
							this.queryData();
						}
					});
			});
		},
		// 隐藏展示
		handleTitle(i) {
			if (this.isFullScreen) {
				return;
			}
			if (this.activeNames.includes(i)) {
				this.activeNames = this.activeNames.filter((val) => val !== i);
			} else {
				this.activeNames.push(i);
			}
		},
		// 鼠标进入
		mouseEnter(row, inx, key) {
			this.showOperation = [inx, key, row.tableId];
		}
	},
	beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
.app-container {
	.wo_table_item {
		// height: 600px;
		width: 100%;
		// margin: 20px 0;
		.arrowWrap {
			display: flex;
			justify-content: center;
			padding-right: 10px;
		}
		.arrow {
			width: 50%;
			height: 20px;
			background-color: #6891ef;
			position: relative;
			margin: 0 10px;
			&::after {
				content: '';
				position: absolute;
				z-index: 16;
				right: -38px;
				top: -10px;
				border: 20px solid;
				border-color: transparent transparent transparent #6891ef;
				width: 0;
				height: 0;
			}
		}
		.contentWord {
			white-space: pre-line;
			line-height: 22px;
			word-break: break-all;
			font-size: 14px;
		}
	}
	.titleRow {
		cursor: pointer;
		// height: 40px;
		// line-height: 40px;
		// border-bottom: 1px solid #dcdcdc;
		padding: 10px;
		display: inline-flex;
		width: 100%;
		align-items: center;
		.titleText {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.4);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.is-round {
			padding: 7px;
		}
	}

	.views_wrap {
		background: #fff;

		.wo_table_wrap {
			position: relative;
			overflow: hidden;
			border-top: 1.8px solid #dcdcdc;
			.menuTips {
				position: fixed;
			}
		}
		.view_item {
			// box-shadow: 0px 0px 2px #999;
			// margin: 2px;
			margin-bottom: 20px;
			border: 1.8px solid #dcdcdc;
		}
		.fullscreen {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 1001;
			background: #fff;
		}
	}
	.el-tag {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 0 0;
		justify-content: center;
	}
}
</style>
<style lang="scss">
.overText {
	max-width: 900px;
}
.queryForm {
	.formStyle {
		margin-bottom: 12px;
	}
	.el-form-item {
		margin-bottom: 10px;
	}
}
.wo_table_wrap {
	.wo_table_item {
		.el-table__row .el-table__cell .cell {
			height: 100%;
			.mergeDiv {
				display: flex;
				justify-content: space-between;
				min-height: 20px;
				height: 100%;
				align-items: center;
			}
		}
		.el-table__row {
			height: 120px;
			&:nth-child(1) td {
				border-bottom: 1px solid #6891ef;
				&:nth-child(1) {
					border-right: 1px solid #6891ef;
					&::before {
						content: ' S \A 优势';
						position: absolute;
						z-index: 16;
						top: calc(50% - 20px);
						left: -32px;
						white-space: pre;
					}
				}
			}
			&:nth-last-child(1) td {
				border-bottom: none;
				&:nth-child(1) {
					border-right: 1px solid #6891ef;
					&::before {
						content: ' W \A 劣势';
						position: absolute;
						z-index: 16;
						top: calc(50% - 20px);
						left: -32px;
						white-space: pre;
					}
					&::after {
						content: 'O 机会';
						position: absolute;
						z-index: 16;
						bottom: -14px;
						left: calc(50% - 20px);
					}
				}
				&:nth-child(2) {
					&::after {
						content: ' T 威胁';
						position: absolute;
						z-index: 16;
						bottom: -14px;
						left: calc(50% - 20px);
					}
				}
			}
		}
		.el-table--border {
			border-top: none;
			border-right: none;
		}
		.tableBase {
			.el-table--border::after,
			.el-table--group::after,
			.el-table::before {
				background-color: transparent;
			}
		}
		.tableBackground {
			border-left: 1px solid #6891ef;
			border-bottom: 1px solid #6891ef;
			// padding: 20px 20px 30px 30px;
			overflow: unset;
			.el-table__body-wrapper {
				.el-table__body {
					padding: 20px 30px 30px 40px;
					table-layout: unset;
					&::before {
						content: '内\A部\A因\A素';
						position: absolute;
						z-index: 16;
						top: calc(50% - 40px);
						left: -30px;
						white-space: pre;
					}
					&::after {
						content: '外部因素';
						position: absolute;
						z-index: 16;
						bottom: -30px;
						left: calc(50% - 40px);
					}
				}

				overflow: unset;
			}
		}
		.tableBackground::before {
			content: '';
			position: absolute;
			z-index: 16;
			top: -10px;
			left: -5px;
			border: 4px solid;
			border-bottom: 10px solid;
			border-color: transparent transparent #6891ef transparent;
			width: 0;
			height: 0;
		}
		.tableBackground::after {
			content: '';
			position: absolute;
			z-index: 16;
			right: -10px;
			bottom: -4px;
			border: 4px solid;
			border-left: 10px solid;
			border-color: transparent transparent transparent #6891ef;
			width: 0;
			height: 0;
		}
	}
}
</style>
